import "./App.css";
import Home from "./pages/Home/Home";
import { Layout } from "antd";
import React from "react";
import Groups from "./pages/Groups/Groups";
import Professors from "./pages/Professors/Professors";
import Auditories from "./pages/Auditories/Auditories";
import { PrintSchedule } from "./pages/Schedule/PrintSchedule";
import Login from "./pages/Login/Login";
import FinishLogin from "./pages/Login/FinishLogin";
import Profile from "./pages/Profile/Profile";
import AuthMobile from "./pages/AuthMobile/authMobile";
import InTimeMenu from "./components/InTimeMenu";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Schedule from "./pages/Schedule/Schedule";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

const { Header, Content } = Layout;

const MainLayout = () => {
  return (
    <Layout className="layout">
      <Header>
        <InTimeMenu />
      </Header>

      <Content style={{ padding: "0 24px" }}>
        <Routes>
          <Route path="/groups" element={<Groups />} />
          <Route path="/professors" element={<Professors />} />
          <Route path="/auditories" element={<Auditories />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/finish" element={<FinishLogin />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/schedule/:type/:scheduleId" element={<Schedule />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Content>
    </Layout>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/schedule/print" element={<PrintSchedule />} />
        <Route path="/auth/mobile/success" element={<AuthMobile />} />
        <Route path="*" element={<MainLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
