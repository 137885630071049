import React from "react";
import "./home.scss";
import { Row, Col, Typography, Button } from "antd";
import {
  TeamOutlined,
  ContactsOutlined,
  BankOutlined,
} from "@ant-design/icons";
import schedule_app from "../../images/promo.png";
import google_play_badge from "../../images/google-play-badge.png";
import app_store_badge from "../../images/app-store-badge.svg";
import ru_store_badge from "../../images/ru-store-badge.svg";
import alice_badge from "../../images/alice-badge.svg";
import { useNavigate } from "react-router-dom";
import newFirst from "./assets/new-1.png";
import newSecond from "./assets/new-2.png";
const { Title } = Typography;

function Home() {
  const navigate = useNavigate();
  return (
    <>
      <Row className="home_wrapper">
        <Col xs={24} sm={16} md={24} lg={11}>
          <div className={"home_content-container"}>
            <Title level={2}>Расписание</Title>
            <div className={"home_section-buttons"}>
              <Button
                onClick={() => {
                  navigate("/groups");
                }}
              >
                <TeamOutlined />
                Группы
              </Button>
              <Button
                onClick={() => {
                  navigate("/professors");
                }}
              >
                <ContactsOutlined />
                Преподаватели
              </Button>
              <Button
                onClick={() => {
                  navigate("/auditories");
                }}
              >
                <BankOutlined />
                Аудитории
              </Button>
            </div>
          </div>
        </Col>
        <Col className={"home_promo-banner"}>
          <Title level={3}>Используй на других устройствах</Title>
          <img
            className={"home_promo-screenshot"}
            src={schedule_app}
            alt="promo-screenshot"
          />

          <table style={{ borderSpacing: "8px", borderCollapse: "separate" }}>
            <tr>
              <td>
                <a
                  href={
                    "https://play.google.com/store/apps/details?id=com.tsu.InTime"
                  }
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <img
                    src={google_play_badge}
                    className={"store-badge"}
                    alt="google play badge"
                  />
                </a>
              </td>
              <td>
                <a
                  href={
                    "https://apps.apple.com/ru/app/tsu-intime-расписание/id1451341418"
                  }
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <img
                    src={app_store_badge}
                    className={"store-badge"}
                    alt="app store badge"
                  />
                </a>
              </td>
              <td>
                <a
                  href='https://dialogs.yandex.ru/store/skills/813c215c-testovyj-navyk?utm_source=site&amp;utm_medium=badge&amp;utm_campaign=v1&amp;utm_term=d1"'
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <img
                    src={alice_badge}
                    className={"store-badge"}
                    alt="Алиса это умеет"
                  />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href={"https://apps.rustore.ru/app/com.tsu.InTime"}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <img
                    src={ru_store_badge}
                    className={"store-badge"}
                    alt="ru store badge"
                  />
                </a>
              </td>
            </tr>
          </table>
        </Col>
      </Row>

      <div className="new">
        <div className="new-version-card">
          <span className="title">Встречайте новую версию TSU.InTime</span>
          <span className="text">
            Новые оформление и возможности: сохранение расписаний, просмотр
            успеваемости, компактный вид расписания и многое другое
          </span>
          <a href="https://new.intime.tsu.ru" target="_blank" className="new-link">
            Перейти на новую версию
          </a>

          <img src={newSecond} alt="new-1" />
          <img src={newFirst} alt="new-2" />
        </div>
      </div>
    </>
  );
}

export default Home;
